import React, {Component} from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import Logo from '../assets/mahameru3.jpg';
import logo_2 from '../assets/maha_logo_horizontal.jpg';

class NavigationBarNew extends Component {
    
    constructor(props){
        super(props)
        if(window.location.href.includes('agriculture')){
            this.state = {
                homecolor: '',
                facilitycolor: '#e9e9e9',
                qualitycolor: '',
                marketcolor: '',
                aboutcolor: '',
                contactcolor: '',
                diwalicolor: '',
                mineralcolor:'',
                catalogcolor:''
            }
        }
        else if (window.location.href.includes('software')){
            this.state = {
                homecolor: '',
                facilitycolor: '',
                qualitycolor: '#e8e8e8',
                marketcolor: '',
                aboutcolor: '',
                contactcolor: '',
                diwalicolor: '',
                mineralcolor:'',
                catalogcolor:''
            }
        }
        else if (window.location.href.includes('career')){
            this.state = {
                homecolor: '',
                facilitycolor: '',
                qualitycolor: '',
                marketcolor: '#e8e8e8',
                aboutcolor: '',
                contactcolor: '',
                diwalicolor: '',
                mineralcolor:'',
                catalogcolor:''
            }
        }
        else if (window.location.href.includes('about')){
            this.state = {
                homecolor: '',
                facilitycolor: '',
                qualitycolor: '',
                marketcolor: '',
                aboutcolor: '#e8e8e8',
                contactcolor: '',
                diwalicolor: '',
                mineralcolor:'',
                catalogcolor:''
            }
        }
        else if (window.location.href.includes('contact')){
            this.state = {
                homecolor: '',
                facilitycolor: '',
                qualitycolor: '',
                marketcolor: '',
                aboutcolor: '',
                contactcolor: '#e8e8e8',
                diwalicolor: '',
                mineralcolor:'',
                catalogcolor:''
            }
        }
        else if (window.location.href.includes('animal')){
            this.state = {
                homecolor: '',
                facilitycolor: '',
                qualitycolor: '',
                marketcolor: '',
                aboutcolor: '',
                contactcolor: '',
                diwalicolor: '#e8e8e8',
                mineralcolor:'',
                catalogcolor:''
            }
        }
        else if (window.location.href.includes('events')){
            this.state = {
                homecolor: '',
                facilitycolor: '',
                qualitycolor: '',
                marketcolor: '',
                aboutcolor: '',
                contactcolor: '',
                diwalicolor: '',
                mineralcolor:'#e8e8e8',
                catalogcolor:''
            }
        }
        else if (window.location.href.includes('catalog')){
            this.state = {
                homecolor: '',
                facilitycolor: '',
                qualitycolor: '',
                marketcolor: '',
                aboutcolor: '',
                contactcolor: '',
                diwalicolor: '',
                mineralcolor:'',
                catalogcolor:'#e8e8e8'
            }
        }
        else{
            this.state = {
                homecolor: '#e9e9e9',
                facilitycolor: '',
                qualitycolor: '',
                marketcolor: '',
                aboutcolor: '',
                contactcolor: '',
                diwalicolor: '',
                mineralcolor:'',
                catalogcolor:''
            }
        }
    }

    render() {
        return(
            
        <Navbar className="NavigationBarNew" expand="lg" style={{padding: 0}}>
        <Navbar.Brand href="/">
        <img
            src={logo_2}
            // className="d-inline-block align-top"
            // className="myimg"
            width={130}
            height={130}
            
            alt="React Bootstrap logo"
        />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
                <Nav.Item><Nav.Link href="/" style={{background: this.state.homecolor}}><b>Home</b></Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/about" style={{background: this.state.aboutcolor}}><b>About</b></Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/agriculture" style={{background: this.state.facilitycolor}}><b>Agriculture</b></Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/animal" style={{background: this.state.diwalicolor}}><b>Animal</b></Nav.Link></Nav.Item>
                {/* <Nav.Item><Nav.Link href="/software" style={{background: this.state.qualitycolor}}><b>Software</b></Nav.Link></Nav.Item> */}
                {/* <Nav.Item><Nav.Link href="/mineral" style={{background: this.state.mineralcolor}}><b>Minerals</b></Nav.Link></Nav.Item> */}
                {/* <Nav.Item><Nav.Link href="/catalog" style={{background: this.state.catalogcolor}}><b>Catalogue</b></Nav.Link></Nav.Item> */}
                <Nav.Item><Nav.Link href="/career" style={{background: this.state.marketcolor}}><b>Careers</b></Nav.Link></Nav.Item>
                {/* <Nav.Item><Nav.Link href="/events" style={{background: this.state.mineralcolor}}><b>Events</b></Nav.Link></Nav.Item> */}
                <Nav.Item><Nav.Link href="/contact" style={{background: this.state.contactcolor}}><b>Contact</b></Nav.Link></Nav.Item>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
        )
    }
}

export default NavigationBarNew;