import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Pages/Home';
import { About } from './Pages/About';
import { Contact } from './Pages/Contact';
import { NoMatch } from './Pages/NoMatch';
import { Agriculture } from './Pages/Agriculture';
import Invoice from './Pages/Invoice';
import { Mineral } from './Pages/Mineral';
import { Career } from './Pages/Career';
import { Software } from './Pages/Software';
import { Animal } from './Pages/Animal.js';
import { Mbiome } from './Pages/Agro_products/mbiome.js';
import { Events } from './Pages/Events';
import { Catalog } from './Pages/Catalog';
import NavigationBarNew from './components/NavigationBarNew';
import { FooterComponent } from './components/FooterComponent';

import mterra from './assets/animal/mterra.pdf';
import pest_target from './assets/m-defend/pest_target.pdf';

import maquacare from './assets/pdf/m-aquacare.pdf';
import mdetox from './assets/pdf/m-detox.pdf';
import mperform from './assets/pdf/m-perform.pdf';
import mdefend from './assets/pdf/m-defend.pdf';
import mgeoshade from './assets/pdf/m-geoshade.pdf';
import mgeoshield from './assets/pdf/m-geoshield.pdf';
import mhive from './assets/pdf/m-hive.pdf';
import mvermipro from './assets/pdf/m-vermipro.pdf';
import mterraboost from './assets/pdf/m-terraboost.pdf';
import mbrochure from './assets/pdf/m-brochure.pdf';

import { PDFReader, MobilePDFReader } from 'react-read-pdf';
//TODO: Add Kam profile
//TODO: update website with ECA information
//TODO: update software page with new events software made
//TODO: add aimal feed and animal feed production section
//TODO: Review if subdomains per category are needed to be added like agriculture.maha-meru.com
//TODO: Check if we need to remove the minerals information displayed in the minerals page, maybe we can write it as per use.
//TODO: New photos for each member
//TODO: Add vermicompost and vermiwash etc products


function App() {
  return (
    <div className="page-container">
    <div className="content-wrap">
    <NavigationBarNew />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />

          {/* <Route path="/agriculture/flyer/mflyv1/m-defend/pest-target" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = pest_target;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading the pest targetted my M-Defend file.<br />
                If your download doesn't begin shortly, Please <a href={pest_target}>Click Here </a>to download again.
              </p>
              );
            } else {
              return null;
            }
          }}/>

          <Route path="/agriculture/flyer/mflyv1/m-defend" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mdefend;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-Defend flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mdefend}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mdefend;
              return null;
            }
          }}/>
          <Route path="/agriculture/flyer/mflyv1/m-geoshade" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mgeoshade;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-GeoShade flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mgeoshade}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mgeoshade;
              return null;
            }
          }}/>
          <Route path="/agriculture/flyer/mflyv1/m-geoshield" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mgeoshield;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-GeoShield flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mgeoshield}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mgeoshield;
              return null;
            }
          }}/>
          <Route path="/agriculture/flyer/mflyv1/m-terraboost" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mterraboost;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-TerraBoost flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mterraboost}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mterraboost;
              return null;
            }
          }}/>
          <Route path="/agriculture/flyer/mflyv1/m-hive" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mhive;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-Hive flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mhive}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mhive;
              return null;
            }
          }}/>
          <Route path="/agriculture/flyer/mflyv1/m-vermipro" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mvermipro;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-VermiPro flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mvermipro}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mvermipro;
              return null;
            } */}
          {/* // }}/>
          // <Route path="/brochure/mbrov1" exact component={() => { */}
          {/* //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          //   window.location.href = mbrochure;
          //   if (isMobile) {
          //     return ( */}
          {/* //       <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
          //       Thank you for downloading our brochure.<br />
          //       If your download doesn't begin shortly, Please <a href={mbrochure}>Click here </a>to download again.
          //     </p>
          //     );
          //   } else { */}
          {/* //     window.location.href = mbrochure;
          //     return null;
          //   }
          // }}/> */}

          {/* <Route path="/agriculture/mbiome" component={Mbiome} /> */}
          <Route path="/agriculture" component={Agriculture} />
          {/* <Route path="/software" component={Software} /> */}
{/*           
          <Route path="/animal/flyer/mflyv1/m-terradry" exact component={() => {
             const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
             window.location.href = mterra;
             if (isMobile) {
               return (
                 <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                 Thank you for downloading M-TerraDry flyer file.<br />
                 If your download doesn't begin shortly, Please <a href={mterra}>Click here </a>to download again.
               </p>
               );
             } else {
               window.location.href = mterra;
               return null;
             }
          }}/>
          <Route path="/animal/flyer/mflyv1/m-detox" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mdetox;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-DeTox flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mdetox}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mdetox;
              return null;
            }
          }}/>
          <Route path="/animal/flyer/mflyv1/m-perform" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = mperform;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-Perform flyer file.<br />
                If your download doesn't begin shortly, Please <a href={mperform}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = mperform;
              return null;
            }
          }}/>
          <Route path="/fish/flyer/mflyv1/m-aquacare" exact component={() => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            window.location.href = maquacare;
            if (isMobile) {
              return (
                <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '20px', fontSize: '20px'}}>
                Thank you for downloading M-AquaCare flyer file.<br />
                If your download doesn't begin shortly, Please <a href={maquacare}>Click here </a>to download again.
              </p>
              );
            } else {
              window.location.href = maquacare;
              return null;
            }
          }}/> */}

          <Route path="/animal" component={Animal} />
          <Route path="/career" component={Career} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          {/* <Route path="/mineral" component={Mineral} />
          <Route path="/invoice" component={Invoice} /> */}
          {/* <Route path="/events" component={Events} /> */}
          {/* <Route path="/catalog" component={Catalog} /> */}
          <Route component={NoMatch} />
        </Switch>
      </Router>
      </div>
      <FooterComponent />
      </div>
  );
}

export default App;